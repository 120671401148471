import {
  Button,
  DialogActions,
  DialogContent,
  Hidden,
  IconButton,
  makeStyles,
  Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ClearIcon from "@material-ui/icons/Clear";
import { useState } from "react";
import classNames from "classnames";
import CustomDropzone from "../components/Dropzone";
import FileList from "../components/FileList";
import AddIcon from "@material-ui/icons/Add";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { useCallback, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import hasInjectionAttack from "../../../../../utils/checkInjectionAttack";
import { showError } from "../../Notify";

const useStyles = makeStyles(theme => ({
  mobileButtoContainer: {
    paddingLeft: "16px",
    paddingRight: "16px",

    paddingBottom: "16px"
  },

  buttonRoot: {
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  root: {
    [theme.breakpoints.up("md")]: {
      width: "640px"
    }
  },
  headerContainer: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      minHeight: "56px",
      display: "flex",
      alignItems: "center"
    }
  },
  headerRoot: {
    width: "100%",
    paddingTop: "48px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "8px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      position: "initial",
      padding: "0"
    },
    "&.advanced": {
      paddingTop: "48px",
      paddingBottom: "0"
    },
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "10px",
        padding: "0"
      },
      display: "flex",
      gap: "10px"
    },
    "& .MuiIconButton-root": {
      [theme.breakpoints.up("md")]: {
        right: "1px"
      },
      [theme.breakpoints.down("sm")]: {
        left: "1px",
        position: "initial"
      },
      position: "absolute",
      top: "1px"
    }
  },
  buttons: {
    display: "flex",
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  fileSelection: {
    margin: "0 -24px"
  },
  addIcon: {
    marginRight: "4px"
  },
  dropzoneContainer: {
    padding: "41px 21px 41px 21px",
    background: "rgba(21, 17, 36, 0.05)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: "8px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    lineHeight: "43px",
    letterSpacing: ".25px",
    color: "#151124",
    marginTop: "8px",
    marginBottom: "24px"
  },
  dropzoneContent: {
    display: "flex",
    "& p": {
      margin: 0,
      lineHeight: "24px",
      fontWeight: "400",
      letterSpacing: "0.25px",
      "& > a": {
        color: "#276EF1",
        textDecoration: "none"
      }
    }
  },
  actionButtons: {
    paddingTop: "16px !important",
    [theme.breakpoints.down("sm")]: {
      background: "white",
      zIndex: "9",
      position: "fixed",
      bottom: "0",
      right: "0",
      left: "0",
      boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.12)",
      paddingTop: "24px!important"
    }
  }
}));

export default ({
  files,
  type,
  title,
  onClose,
  setFiles,
  setPrevStepAction,
  setNextStepAction,
  accept,
  disableInvoiceUpload = false
}) => {
  const classes = useStyles();
  const dropzoneRef = useRef();
  const { t } = useTranslation();
  const theme = useTheme();
  const [emptyFileName, setEmptyFileName] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const openUploadDialog = useCallback(e => {
    e.preventDefault();
    dropzoneRef.current && dropzoneRef.current.open();
  }, []);

  const isNextButtonDisabled = (files, type) => {
    let doWeHaveFiles = files && files.length > 0;

    if (type === "other") {
      return !(
        doWeHaveFiles &&
        files.filter(singleFile => singleFile.documentType === undefined)
          .length === 0
      );
    }
    return !doWeHaveFiles;
  };

  const addFilesAction = useCallback(
    fileList => {
      const fileListArray = Array.from(fileList);
      setFiles([...files, ...fileListArray]);
    },
    [files, setFiles]
  );

  const handleSetNextStepAction = () => {
    //We check if the name of any file could include injection attack
    for (let i = 0; i < files.length; i++) {
      if (hasInjectionAttack(files[i].name)) {
        showError(t("error_injection_attack"));
        return;
      }
    }
    setNextStepAction();
  };

  return (
    <CustomDropzone accept={accept} onDrop={addFilesAction} ref={dropzoneRef}>
      <div className={classes.headerContainer}>
        <div className={classes.headerRoot}>
          <Hidden mdUp>
            <IconButton
              aria-label="delete"
              onClick={onClose}
              className={classes.closeButton}
            >
              <ClearIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h2" gutterBottom className={classes.title}>
            {title}
          </Typography>
          <Hidden mdDown>
            <IconButton
              aria-label="delete"
              onClick={onClose}
              className={classes.closeButton}
            >
              <ClearIcon />
            </IconButton>
          </Hidden>
        </div>
      </div>
      <Hidden mdUp>
        <div className={classNames(classes.mobileButtoContainer)}>
          <Button
            onClick={openUploadDialog}
            variant="outlined"
            color="primary"
            fullWidth
            startIcon={<CameraAltIcon />}
          >
            {t("ImageMobileTakePhoto")}
          </Button>
        </div>
        <div className={classNames(classes.mobileButtoContainer)}>
          <Button
            onClick={openUploadDialog}
            variant="outlined"
            color="primary"
            fullWidth
            startIcon={<InsertDriveFileIcon />}
          >
            {t("SELECT FILES")}
          </Button>
        </div>
      </Hidden>
      <DialogContent className={classNames(classes.root)}>
        <Hidden smDown>
          <div className={classes.dropzoneContainer}>
            <div className={classes.dropzoneContent}>
              <AddIcon className={classes.addIcon} />
              <p>
                {t("DragDropWindow1")}{" "}
                <a href="#" onClick={openUploadDialog}>
                  {t("DragDropWindowBrowse")}
                </a>
                <br />
                {t("DragDropWindow2")}
              </p>
            </div>
          </div>
        </Hidden>
        <FileList
          fileTypeSelector={type === "other" ? true : false}
          files={files}
          setFiles={setFiles}
          className={classes.fileSelection}
          disableInvoiceUpload={disableInvoiceUpload}
          setEmptyFileName={setEmptyFileName}
        />
      </DialogContent>

      <DialogActions className={classes.actionButtons}>
        <Button
          color="primary"
          classes={{ root: classes.buttonRoot }}
          variant="outlined"
          onClick={() => {
            setFiles([]);
            setPrevStepAction();
          }}
        >
          {t("Back")}
        </Button>
        <Button
          classes={{ root: classes.buttonRoot }}
          elevation={0}
          color="secondary"
          variant="contained"
          onClick={handleSetNextStepAction}
          disabled={isNextButtonDisabled(files, type) || emptyFileName}
        >
          {t("Next")}
        </Button>
      </DialogActions>
    </CustomDropzone>
  );
};
