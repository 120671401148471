import { useProductFruitsApi } from "react-product-fruits";
import { SURVEYS } from "../utils/constants";
export const useProductFruitsSurvey = () => {
  const startSurvey = type => {
    if (localStorage.getItem("surveyId") === type)
      localStorage.removeItem("surveyId");
    let surveyId = SURVEYS[type];
    useProductFruitsApi(
      api => {
        if (!type) {
          return;
        }
        if (type === "GENERAL") {
          setTimeout(() => {
            api.surveys.startSurvey(surveyId);
          }, 30000);
        } else {
          api.surveys.startSurvey(surveyId);
        }
      },
      [surveyId]
    );
  };
  return { startSurvey };
};
