import React from "react";
import { DialogContent, Typography, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import thanksPageImg from "../../assets/images/thanks_page_background.gif";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
const useStyles = makeStyles(theme => ({
  pageContainer: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      left: "55%",
      transform: "translateX(-50%)",
      width: "600px"
    }
  },
  container: {
    display: "flex",
    position: "fixed",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
    height: "100vh",
    position: "fixed",
    [theme.breakpoints.up("md")]: {
      justifyContent: "center"
    }
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  image: {
    height: "100px",
    width: "100px",
    [theme.breakpoints.up("md")]: {
      height: "200px",
      width: "200px"
    }
  },
  text: {
    marginTop: theme.spacing(2),
    fontSize: "16px",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("md")]: {
      fontSize: "20px"
    }
  },
  headerContainer: {
    width: "100%",
    paddingTop: "100px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "8px",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      position: "initial",
      padding: "0"
    },
    "&.advanced": {
      paddingTop: "48px",
      paddingBottom: "0"
    },
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "10px",
        paddingLeft: "0px"
      },
      display: "flex",
      gap: "10px"
    },
    "& .MuiIconButton-root": {
      [theme.breakpoints.up("md")]: {
        right: "1px"
      },
      [theme.breakpoints.down("sm")]: {
        left: "1px",
        position: "initial"
      },
      position: "absolute",
      top: "1px"
    }
  },
  closeButton: {
    padding: "10px"
  },
  thankYouText: {
    fontWeight: "500",
    fontSize: "20px",
    fontFamily: "Roboto",
    lineHeight: "24px",
    [theme.breakpoints.up("md")]: {
      fontSize: "28px"
    }
  },
  text: {
    color: "gray",
    fontSize: "16px",
    fontFamily: "Roboto",
    lineHeight: "24px",
    paddingTop: "10px",
    paddingBottom: "10px"
  }
}));

export default function ThankYouPage({ closingModal }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.pageContainer}>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <Hidden mdUp>
              <IconButton
                aria-label="close"
                onClick={() => closingModal("closeTab")}
                className={classes.closeButton}
              >
                <ClearIcon />
              </IconButton>
              <Typography variant="h2" gutterBottom>
                {"Upload Documents"}
              </Typography>
            </Hidden>
          </div>
          <DialogContent className={classes.root}>
            <img
              src={thanksPageImg}
              alt="Thank you"
              className={classes.image}
            />
            <Typography className={classes.text}>
              {"The files you selected were uploaded successfully."}
            </Typography>
            <Typography className={classes.thankYouText}>
              {"Thank you!"}
            </Typography>
          </DialogContent>
        </div>
      </div>
    </>
  );
}
