import gql from "graphql-tag";

export default gql`
  mutation checkDriverToken($token: String!) {
    checkDriverToken(token: $token) {
      valid
      message
      data {
        order
        partner
        product
        token
        type
        expiresIn
      }
    }
  }
`;
