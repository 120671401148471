import React, { useCallback, useMemo, useEffect } from "react";
import {
  DialogContent,
  DialogContentText,
  Typography,
  DialogActions,
  Button,
  Hidden
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import ProgressBar from "../../components/general/ProgressBar";
import FileList from "../../components/core/shared/uploadDialog/components/FileList";
import UPLOAD_FILE_MUTATION from "../../mutations/uploadFileMutation";
import { useMutation } from "@apollo/react-hooks";
import { MAX_PORTAL_UPLOAD_FILE_SIZE } from "../../utils/constants";
import { TBD_FILE_TYPE } from "../../components/core/shared/uploadDialog/components/FileList/fileTypeOptions";
import FileIcon from "../../assets/icons/file.png";
const useStyles = makeStyles(theme => ({
  pageContainer: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "640px"
    }
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "white",
      overflow: "hidden"
    }
  },
  headerContainer: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      minHeight: "56px",
      display: "flex",
      alignItems: "center",
      marginTop: "0px"
    }
  },
  headerRoot: {
    width: "100%",
    paddingTop: "48px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "8px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      position: "initial",
      padding: "0"
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: "14px"
    },
    "&.advanced": {
      paddingTop: "48px",
      paddingBottom: "0"
    },
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "12px"
      },
      display: "flex",
      gap: "10px"
    },
    "& .MuiIconButton-root": {
      [theme.breakpoints.up("md")]: {
        right: "1px"
      },
      [theme.breakpoints.down("sm")]: {
        left: "1px",
        position: "initial"
      },
      position: "absolute",
      top: "1px"
    }
  },
  fileSelection: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "white",
      borderWidth: "0px",
      maxHeight: "200px"
    },
    margin: "0 -17px"
  },
  closeButton: {
    padding: "12px"
  },
  progressBar: {
    marginBottom: "16px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "8px"
    }
  },
  actionButtons: {
    [theme.breakpoints.down("sm")]: {
      background: "white",
      zIndex: "9",
      position: "fixed",
      bottom: "0",
      right: "0",
      left: "0"
    }
  },
  buttonRoot: {
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "40px"
    }
  },
  text: {
    paddingBottom: "15px"
  },
  root: {
    paddingRight: "17px !important",
    paddingLeft: "17px !important"
  },
  fileIcon: {
    width: "24px",
    height: "24px",
    marginRight: "8px"
  }
}));

export default function SecondStep({
  files,
  setFiles,
  closingModal,
  driverTokenValidity,
  setStep
}) {
  const classes = useStyles();

  const [addFileMutation] = useMutation(UPLOAD_FILE_MUTATION);

  const handleUploadFiles = useCallback(async () => {
    for (let file of files) {
      const { size } = file;
      if (size > MAX_PORTAL_UPLOAD_FILE_SIZE) {
        closingModal("limitExceeded");
        return;
      }
      await addFileMutation({
        variables: {
          file,
          input: {
            token: driverTokenValidity?.data?.token,
            orderId: driverTokenValidity?.data?.order?._id,
            collection: "PartnerUploads",
            meta: {
              uploadByDriver: true,
              productId: driverTokenValidity?.data?.product?.productID,
              documentType: TBD_FILE_TYPE
            }
          }
        }
      });
      file.completed = true;
      setFiles([...files]);
    }
    setStep(3);
  }, [files, addFileMutation, setFiles, closingModal, driverTokenValidity]);

  const [actualFile, totalFiles, percentage] = useMemo(() => {
    const totalFiles = files.length;
    const uploadingFile = files.findIndex(f => !f.completed);
    const actualFile = uploadingFile === -1 ? totalFiles : uploadingFile;
    const percentage = (actualFile / totalFiles) * 100;
    return [actualFile, totalFiles, percentage];
  }, [files]);

  useEffect(() => {
    handleUploadFiles();
  }, []);

  return (
    <>
      <div className={classes.pageContainer}>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <div className={classes.headerRoot}>
              <Hidden mdUp>
                <IconButton
                  aria-label="delete"
                  onClick={() => closingModal("closeTab")}
                  className={classes.closeButton}
                >
                  <ClearIcon />
                </IconButton>
              </Hidden>
              <Hidden smDown>
                <img src={FileIcon} alt="logo" class={classes.fileIcon} />
              </Hidden>
              <Typography variant="h2" gutterBottom>
                {"Upload Documents"}
              </Typography>
            </div>
          </div>
          <DialogContent className={classes.root}>
            <DialogContentText className={classes.text}>
              {"The files you selected are being uploaded"} <br />
              {
                "Please do not close or refresh the browser window until the upload process is completed"
              }
            </DialogContentText>
            <ProgressBar
              className={classes.progressBar}
              percentage={percentage}
              text={`${actualFile} of ${totalFiles} files uploaded`}
            />
            <FileList
              files={files}
              className={classes.fileSelection}
              uploading={true}
              isDriver={true}
            />
          </DialogContent>
          <DialogActions className={classes.actionButtons}>
            <Hidden mdUp>
              <Button
                classes={{ root: classes.buttonRoot }}
                elevation={0}
                color="secondary"
                variant="contained"
                disabled={true}
              >
                {"FINISH"}
              </Button>
            </Hidden>
          </DialogActions>
        </div>
      </div>
    </>
  );
}
