export const WEIGHTNOTE_FILE_TYPE = "weightNote";
export const OTHER_DOC_FILE_TYPE = "otherDoc";
export const MATERIAL_IMAGES_FILE_TYPE = "materialDoc";
export const INVOICE_FILE_TYPE = "invoice";
export const CREDIT_NOTE_FILE_TYPE = "creditNote";
export const TBD_FILE_TYPE = "TBD";

export const FILE_TYPES_OPTIONS_WITHOUT_TRANSLATE = {
  aditionalCosts: "Additional Cost",
  contract: "Contract",
  creditNote: "Credit note",
  [INVOICE_FILE_TYPE]: "Invoice",
  materialDoc: "Material analysis",
  [OTHER_DOC_FILE_TYPE]: "Other",
  transportDocument: "Transport document",
  [WEIGHTNOTE_FILE_TYPE]: "Weightnote"
};

const FILE_TYPES_OPTIONS = [
  { value: "aditionalCosts", label: "UploadFiles_Type_AdditionalCost" },
  { value: "contract", label: "UploadFiles_Type_Contract" },
  { value: CREDIT_NOTE_FILE_TYPE, label: "UploadFiles_Type_CreditNote" },
  { value: INVOICE_FILE_TYPE, label: "UploadFiles_Type_Invoice" },
  { value: "materialDoc", label: "UploadFiles_Type_MatAnalysis" },
  { value: OTHER_DOC_FILE_TYPE, label: "UploadFiles_Type_Other" },
  { value: "transportDocument", label: "UploadFiles_Type_TransportDoc" },
  { value: WEIGHTNOTE_FILE_TYPE, label: "UploadFiles_Type_WeightNote" }
];

export const getDataTypesForOtherFileType = (t, disableInvoiceUpload) => {
  let dataTypes = FILE_TYPES_OPTIONS.map(({ label, value }) => ({
    label: t(label),
    value
  }));

  if (disableInvoiceUpload) {
    dataTypes = dataTypes.filter(
      ({ value }) =>
        value !== INVOICE_FILE_TYPE && value !== CREDIT_NOTE_FILE_TYPE
    );
  }

  return dataTypes;
};

export default FILE_TYPES_OPTIONS;
