import { Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Metaloop } from "../../assets/logo.svg";

const useStyles = makeStyles(theme => ({
  navbar: {
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      width: "100%",
      backgroundColor: "#151124",
      height: "48px",
      alignItems: "center"
    }
  },
  navbarContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    paddingTop: "18px",
    paddingBottom: "18px",
    paddingLeft: "24px"
  }
}));

export default function NavBar() {
  const classes = useStyles();
  return (
    <Hidden smDown>
      <div className={classes.navbar}>
        <Metaloop className={classes.navbarContent} />
      </div>
    </Hidden>
  );
}
