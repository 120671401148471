import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useLocation } from "react-router";
import store from "./configureStore";
import Layout from "./Layout";
import LoggedOutLayout from "./LoggedOutLayout";
import DashboardPage from "./pages/dashboard/Dashboard";
import HomePage from "./pages/home/Home";
import ForgotPage from "./pages/forgot/Forgot";
import ResetPage from "./pages/forgot/Reset";
import LoginPage from "./pages/login/Login";
import LoginLinkPage from "./pages/login/LoginLink";
import NotAuthorizedPage from "./pages/NotAuthorizedPage";
import PricingPage from "./pages/pricing/PricingPage";
import AccountsPage from "./pages/profile/Profile";
import TransactionsPage from "./pages/transactions/TransactionsPage";
import UnauthWizard from "./pages/unauth-wizard/UnauthWizard";
import VideosPage from "./pages/VideosPage";
import ZonesPage from "./pages/zones/ZonesPage";
import NotForMobilePage from "./pages/NotForMobilePage";
import VerificationPage from "./pages/verification/Verification";
import OffersRoute from "./pages/offers/OffersRoute";
import InfoNavBar from "./components/core/shared/InfoNavBar";
import ProductsRoute from "./pages/products/ProductsRoute";
import { PickupsRoute } from "./pages/pickups/PickupsRoute";
import Impersonation from "./components/account/impersonation/Impersonation";
import ErrorBoundary from "./ErrorTracking/ErrorBoundary";
import Notifications from "./pages/notifications";
import ImportPage from "./pages/import/ImportPage";
import Onboarding from "./pages/onboarding";
import FileUploadPage from "./pages/uploadDocumentViaQr";

const AuthRedirect = () => <Redirect to="login" />;

const RenderWithLayout = (Component, leftNavigation) => (
  <ErrorBoundary>
    <Layout
      leftNavigation={
        typeof leftNavigation !== "undefined" ? leftNavigation : true
      }
    >
      {Component}
    </Layout>
  </ErrorBoundary>
);
const RenderWithoutLayout = Component => (
  <LoggedOutLayout>{Component}</LoggedOutLayout>
);

export const AUTH_ROUTES = [
  "/home",
  "/dashboard",
  "/profile/payment",
  "/profile/information",
  "/profile/gallery",
  "/profile/security",
  "/transactions",
  "/transactions"
];

export const ALREADY_LOGGEDIN = ["/login", "/forgot", "/register"];

const RedirectWithParams = () => {
  const location = useLocation();
  const searchParams = location.search;
  return <Redirect to={`/upload-document${searchParams}`} />;
};
export default () => (
  <Provider store={store}>
    <BrowserRouter basename="/">
      <InfoNavBar>
        <Switch>
          <Route exact path="/" component={AuthRedirect} />
          <Route
            path="/login"
            render={() => RenderWithoutLayout(<LoginPage />)}
          />
          <Route
            path="/magiclink/:loginToken"
            render={() => RenderWithoutLayout(<LoginPage />)}
          />
          <Route
            path="/onboarding/:step"
            render={() => RenderWithoutLayout(<Onboarding />)}
          />
          <Route
            path="/onboarding"
            render={() => RenderWithoutLayout(<Onboarding />)}
          />
          <Route
            path="/loginLink"
            render={() => RenderWithoutLayout(<LoginLinkPage />)}
          />
          <Route
            path="/signup"
            render={() => RenderWithoutLayout(<UnauthWizard />)}
          />
          <Route
            path="/forgot"
            render={() => RenderWithoutLayout(<ForgotPage />)}
          />
          <Route
            path="/reset-password/:token"
            render={() => RenderWithoutLayout(<ResetPage />)}
          />
          <Route
            exact
            path="/upload-weight-note"
            render={() => <RedirectWithParams />}
          />
          <Route path="/upload-document" render={() => <FileUploadPage />} />
          <Route
            path="/dashboard"
            render={() => RenderWithLayout(<DashboardPage />)}
          />
          <Route path="/home" render={() => RenderWithLayout(<HomePage />)} />
          <Route
            path="/notifications"
            render={() => RenderWithLayout(<Notifications />)}
          />
          <Route
            path="/profile/companyInformation"
            render={() =>
              RenderWithLayout(<AccountsPage queryType="companyInformation" />)
            }
          />
          <Route
            path="/profile/userInformation"
            render={() =>
              RenderWithLayout(<AccountsPage queryType="userInformation" />)
            }
          />
          <Route
            path="/profile/paymentInformation"
            render={() =>
              RenderWithLayout(<AccountsPage queryType="paymentInformation" />)
            }
          />
          <Route
            path="/profile/users/add"
            render={() =>
              RenderWithLayout(<AccountsPage queryType="addUser" />)
            }
          />
          <Route
            path="/profile/users/edit/:id"
            render={() =>
              RenderWithLayout(<AccountsPage queryType="editUser" />)
            }
          />
          <Route
            path="/profile/users"
            render={() => RenderWithLayout(<AccountsPage queryType="users" />)}
          />
          <Route
            path="/profile/documents"
            render={() =>
              RenderWithLayout(<AccountsPage queryType="documents" />)
            }
          />
          <Route
            path="/profile/transport"
            render={() =>
              RenderWithLayout(<AccountsPage queryType="equipment" />)
            }
          />
          <Route
            path="/profile/payment/:code"
            render={() =>
              RenderWithLayout(<AccountsPage queryType="paymentInformation" />)
            }
          />
          <Route
            path="/profile/notifications"
            render={() =>
              RenderWithLayout(<AccountsPage queryType="notifications" />)
            }
          />
          <Route
            path="/pricing"
            render={() => RenderWithLayout(<PricingPage />)}
          />
          <Route
            path="/verification/:code?/:email?"
            render={() => RenderWithoutLayout(<VerificationPage />)}
          />
          <Route
            path="/sales/import"
            render={() => RenderWithLayout(<ImportPage />)}
          />
          <Route
            path="/sales"
            render={() => RenderWithLayout(<TransactionsPage />)}
          />
          <Route
            path="/orders"
            render={() => RenderWithLayout(<TransactionsPage />)}
          />
          <Route
            path="/products"
            render={() => RenderWithLayout(<ProductsRoute />)}
          />
          <Route
            path="/offers"
            render={() => RenderWithLayout(<OffersRoute />)}
          />
          <Route
            path="/pickups"
            render={() => RenderWithLayout(<PickupsRoute />)}
          />
          <Route path="/zones" render={() => RenderWithLayout(<ZonesPage />)} />
          <Route
            path="/videos"
            render={() => RenderWithLayout(<VideosPage />)}
          />
          <Route path="/no-authorized" render={() => <NotAuthorizedPage />} />
          <Route
            path="/not-for-mobile"
            render={() => RenderWithLayout(<NotForMobilePage />)}
          />
          <Route
            path="/impersonate/:token/:objetiveId/:company/"
            render={() => RenderWithLayout(<Impersonation />)}
          />
          <Redirect to="/login" />
        </Switch>
      </InfoNavBar>
    </BrowserRouter>
  </Provider>
);
