import gql from "graphql-tag";

export default gql`
  query GetTableauInfoQuery(
    $token: String!
    $partnerId: String!
    $isDemo: Boolean!
    $urlId: String!
  ) {
    GetTableauInfoQuery(
      token: $token
      partnerId: $partnerId
      isDemo: $isDemo
      urlId: $urlId
    ) {
      token
      url
    }
  }
`;
