import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useProductFruitsApi } from "react-product-fruits";

const useStyles = makeStyles({
  nameContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: "16px",
    marginLeft: "10px",
    cursor: "pointer",
    color: "#FFFFFF",
    fontSize: "12px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: "normal",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    whiteSpace: "nowrap"
  }
});

export const SupportMenu = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  useProductFruitsApi(api => {
    const customLifeRingLauncher = document.querySelector(
      "#customLifeRingButton"
    );
    api.inAppCenter?.attachToCustomElement(customLifeRingLauncher);
  }, []);

  return (
    <div className={classes.nameContainer} id="customLifeRingButton">
      {t("HelpCenter_Nav_Button")}
    </div>
  );
};
