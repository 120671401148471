import React, { useCallback, useState, useRef } from "react";
import {
  Button,
  DialogContent,
  DialogActions,
  Typography,
  Hidden
} from "@material-ui/core";
import FileIcon from "../../assets/icons/file.png";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import CustomDropzone from "../../components/core/shared/uploadDialog/components/Dropzone";
import FileList from "../../components/core/shared/uploadDialog/components/FileList";
const useStyles = makeStyles(theme => ({
  pageContainer: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "70%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "640px"
    }
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "white",
      overflow: "hidden"
    }
  },
  mobileButtoContainer: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "16px"
  },
  buttonRoot: {
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "40px"
    }
  },
  headerContainer: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      minHeight: "56px",
      display: "flex",
      alignItems: "center",
      marginTop: "0px",
      marginBottom: "8px"
    }
  },
  headerRoot: {
    width: "100%",
    paddingTop: "48px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "8px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      position: "initial",
      padding: "0"
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row"
    },
    "&.advanced": {
      paddingTop: "48px",
      paddingBottom: "0"
    },
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "13px"
      },
      display: "flex",
      gap: "10px"
    },
    "& .MuiIconButton-root": {
      [theme.breakpoints.up("md")]: {
        right: "1px"
      },
      [theme.breakpoints.down("sm")]: {
        left: "1px",
        position: "initial"
      },
      position: "absolute",
      top: "1px"
    }
  },
  closeButton: {
    padding: "13px"
  },
  fileSelection: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "white",
      borderWidth: "0px",
      maxHeight: "200px"
    },
    margin: "0 -24px"
  },
  actionButtons: {
    [theme.breakpoints.down("sm")]: {
      background: "white",
      zIndex: "9",
      position: "fixed",
      bottom: "0",
      right: "0",
      left: "0"
    }
  },
  root: {
    paddingTop: "0px !important"
  },
  dropzoneContainer: {
    padding: "41px 21px 41px 21px",
    background: "rgba(21, 17, 36, 0.05)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: "8px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    lineHeight: "43px",
    letterSpacing: ".25px",
    color: "#151124",
    marginTop: "8px",
    marginBottom: "24px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "8px"
    }
  },
  dropzoneContent: {
    display: "flex",
    "& p": {
      margin: 0,
      lineHeight: "24px",
      fontWeight: "400",
      letterSpacing: "0.25px",
      "& > a": {
        color: "#276EF1",
        textDecoration: "none"
      }
    }
  },
  fileIcon: {
    width: "24px",
    height: "24px",
    marginRight: "8px"
  }
}));

export default function FirstStep({
  files,
  setFiles,
  setStep,
  closingModal,
  accept
}) {
  const classes = useStyles();
  const dropzoneRef = useRef();
  const [emptyFileName, setEmptyFileName] = useState(false);
  const openUploadDialog = useCallback(e => {
    e.preventDefault();
    dropzoneRef.current && dropzoneRef.current.open();
  }, []);

  const addFilesAction = useCallback(
    fileList => {
      const fileListArray = Array.from(fileList);
      setFiles([...files, ...fileListArray]);
    },
    [files, setFiles]
  );

  const handleNextStep = () => {
    if (files.length > 0) {
      setStep(2);
    }
  };

  const isNextButtonDisabled = files.length === 0 || emptyFileName;

  return (
    <>
      <CustomDropzone
        accept={accept}
        onDrop={addFilesAction}
        ref={dropzoneRef}
        className={classes.pageContainer}
      >
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <div className={classes.headerRoot}>
              <Hidden mdUp>
                <IconButton
                  aria-label="delete"
                  onClick={() => closingModal("closeTab")}
                  className={classes.closeButton}
                >
                  <ClearIcon />
                </IconButton>
              </Hidden>
              <Hidden smDown>
                <img src={FileIcon} alt="logo" class={classes.fileIcon} />
              </Hidden>
              <Typography variant="h2" gutterBottom>
                {"Upload Documents"}
              </Typography>
            </div>
          </div>
          <Hidden mdUp>
            <div className={classes.mobileButtoContainer}>
              <Button
                onClick={openUploadDialog}
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<CameraAltIcon />}
              >
                {"TAKE PHOTO"}
              </Button>
            </div>
            <div className={classes.mobileButtoContainer}>
              <Button
                onClick={openUploadDialog}
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<InsertDriveFileIcon />}
              >
                {"SELECT FILES"}
              </Button>
            </div>
          </Hidden>
          <DialogContent className={classes.root}>
            <Hidden smDown>
              <div className={classes.dropzoneContainer}>
                <div className={classes.dropzoneContent}>
                  <AddIcon className={classes.addIcon} />
                  <p>
                    {"Drag and drop files in this window to upload or"}{" "}
                    <a href="#" onClick={openUploadDialog}>
                      {"Browse."}
                    </a>
                    <br />
                    {
                      "You can choose multiple jpg, png, gif, bmp and pdf files."
                    }
                  </p>
                </div>
              </div>
            </Hidden>
            <FileList
              fileTypeSelector={false}
              files={files}
              setFiles={setFiles}
              className={classes.fileSelection}
              isDriver={true}
              setEmptyFileName={setEmptyFileName}
            />
          </DialogContent>
          <DialogActions className={classes.actionButtons}>
            <Button
              classes={{ root: classes.buttonRoot }}
              elevation={0}
              color="secondary"
              variant="contained"
              onClick={handleNextStep}
              disabled={isNextButtonDisabled}
            >
              {"UPLOAD"}
            </Button>
          </DialogActions>
        </div>
      </CustomDropzone>
    </>
  );
}
